<template>
    <div class="vx-row flex">
          <feather-icon icon="EditIcon" svgClasses="h-5 w-5 mr-2 hover:text-primary cursor-pointer" @click="editRecord" />
          <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 mr-2 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
          <feather-icon @click="openPopup" icon="FileTextIcon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer"  />
            <vs-popup class="holamundo"  title="Confirm Items" :active.sync="popupActivo" fullscreen >
                   <vs-table
                      multiple
                      v-model="form.selected"
                      pagination
                      maxItems="10"
                      search
                      :data="detailGoods"
                    >
                      <template slot="header">
                         <vs-select
                          class="w-1/5 py-5 px-2"
                          autocomplete
                          label="Tempo Pembayaran"
                          v-model="form.payment_tempo"
                          @input-change="handleInputChange"
                        >
                          <!-- Loop through options and render each as a select item -->
                          <vs-select-item
                            :key="index"
                            :value="item.value"
                            :text="item.label"
                            v-for="(item, index) in paymentOptions"
                          />
                        </vs-select>
                      </template>

                      <template slot="thead">
                        <vs-th sort-key="name">Name</vs-th>
                        <vs-th sort-key="price">Price</vs-th>
                        <vs-th sort-key="amount">Amount</vs-th>
                        <vs-th sort-key="total">Total</vs-th>
                      </template>

                      <template slot-scope="{ data }">
                        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                          <vs-td :data="data[indextr].name">{{ data[indextr].name }}</vs-td>
                          <vs-td :data="data[indextr].price">{{ formatCurrency(data[indextr].price) }}</vs-td>
                          <vs-td :data="data[indextr].amount">
                            <vs-input
                              type="number"
                              v-model.number="data[indextr].amount"
                              @input="updateRowTotal(indextr)"
                            />
                          </vs-td>
                          <vs-td :data="data[indextr].total">{{ formatCurrency(data[indextr].total) }}</vs-td>
                        </vs-tr>
                      </template>
                    </vs-table>

                     <!-- Footer Section -->
                    <div class="table-footer">
                      <div class="summary">
                        <ul>
                          <li>Sub Total: {{ formatCurrency(calculateSubTotal()) }}</li>
                          <li>VAT (11%): {{ formatCurrency(calculateVAT()) }}</li>
                          <li>Grand Total: {{ formatCurrency(calculateGrandTotal()) }}</li>
                        </ul>
                      </div>
                    </div>

              <div class="mt-6 flex justify-end gap-3">
                <vs-button color="danger" type="filled" @click="() => {popupActivo = false}" >Cancel</vs-button>
                <vs-button color="success" type="filled" @click="downloadInvoice">Print</vs-button>
              </div>
           </vs-popup>
      </div>
</template>

<script>
import recipe from "@/recipe";
import axios from 'axios';

export default {
  name: 'CellRendererActions',
  data() {
    return {
      popupActivo: false,
      detailGoods: [],
      currentPage: 1,
      form: {
        payment_tempo: "100%",
        selected: [],
      },

      // Payment options (key-value pairs)
      paymentOptions: [
        { value: '100%', label: 'Payment 100%' },
        { value: 'Q4', label: 'Payment Q4' },
        { value: 'Q3', label: 'Payment Q3' },
        { value: 'Q2', label: 'Payment Q2' },
        { value: 'Q1', label: 'Payment Q1' },
      ],
    }
  },
  computed: {
    encrypt() {
      let id = this.params.data.id;
      return this.$secure.encrypt(id);
    }
  },
  methods: {
      handleInputChange(value) {
      console.log('Selected Value:', value);
    },
    openPopup() {
      this.detailGoods = JSON.parse(this.params.data.detail_goods)
      this.form.selected = this.detailGoods
      if (this.params.data.customer_id != 4 && this.params.data.customer_id != 13) {
        this.popupActivo = true; // Toggling popup active state
      } else {
        this.$vs.notify({
        title: 'Information',
        text: `Template ${this.params.data.customer} belum tersedia`,
        color: "success",
      })
      }
    },
   formatCurrency(value) {
      return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(value);
    },
    calculateSubTotal() {
      return this.form.selected.reduce((sum, item) => sum + (item.total || 0), 0);
    },
    calculateVAT() {
      return this.calculateSubTotal() * 0.11;
    },
    calculateGrandTotal() {
      return this.calculateSubTotal() + this.calculateVAT();
    },
    updateRowTotal(index) {
      const item = this.detailGoods[index];
      item.total = item.price * item.amount;
    },
    downloadInvoice () {
      const payload = {
        id: this.params.data.id,
        payment_tempo: this.form.payment_tempo,
        selected: this.form.selected,
        invoice_number: this.params.data.invoice_number
      }
      this.$store.dispatch('invoiceCustomer/printInvoice', payload).then(resp => console.log("download success")).catch(err => console.log(err))
    },
    showData () {
      this.$router.push({name: 'invoice-customer-show', params: { id: this.encrypt }});
    },
    editRecord () {
      this.$router.push({name: 'invoice-customer-edit', params: { id: this.encrypt }});
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Do you want to delete it ?`,
        accept: this.deleteRecord,
        acceptText: 'Delete'
      })
    },
    deleteRecord () {
      this.$store.dispatch("invoiceCustomer/removeItem", this.params.data.id)
          .then((resp)   => {

            this.params.getData();

            this.showDeleteSuccess(resp)
          })
          .catch(err => {
            this.showDeleteError(err)
          })
    },
    showDeleteSuccess (resp) {
      this.$vs.notify({
        color: 'success',
        title: 'Delete success',
        text: resp.data.message
      })
    },
    showDeleteError (err) {
      this.$vs.notify({
        title:'Opps something error',
        text: err.data.message,
        color:'danger'
      })
    }

  }
}
</script>


<style scoped>
.table-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  padding: 1rem 0;
  border-top: 1px solid #ddd;
}

.table-footer .pagination-left {
  flex: 1;
}

.table-footer .summary {
  flex: 1;
  text-align: right;
}
</style>
